<template>
  <div>
    <b-overlay :show="false" rounded="sm">
    <b-card>
      <b-form class="mt-2" ref="formReport">       
        <input type="hidden" name="task_id" v-model="task_id">
        <!-- form -->
        <b-row>
          <!-- <b-col sm="12">
            <b-form-group label="TITULO DE PRESPUESTO" label-for="description">
              <b-form-textarea name="description" required />
            </b-form-group>
          </b-col> -->
            <!--TODO añadir más campos como tipo_iva, clausula y metodo de pago -->
          <b-col sm="4">
            <b-form-group label="Fecha parte" label-for="budget_date">
              <b-form-input type="date" v-model="budget_date" name="budget_date"></b-form-input>
            </b-form-group>
          
          </b-col>
          <b-col sm="4">           
            <b-form-group label="Serie" label-for="serial_id">
              <v-select
                :options="serials"                
                placeholder="Seleccione serie"
                label="title"
                :getOptionKey="getOptionKey"
                v-model="serial_id"
                name="serial_id"
              />
        
            </b-form-group>
          </b-col>
          <b-col sm="4">           
            <b-form-group label="Método de pago" label-for="payment">
              <v-select
                :options="payment_methods"                
                placeholder="Seleccione método"
                label="title"
                :getOptionKey="getOptionKey"
                v-model="method_id"
                name="payment_method_id"
              />           
            </b-form-group>
          </b-col>
          <b-col sm="4">           
            <b-form-group label="Cláusula" label-for="clause">
              <v-select
                :options="clauses"                
                placeholder="Seleccione cláusula"
                label="title"
                :getOptionKey="getOptionKey"
                name="clause_id"
                v-model="clause_id"
              />           
            </b-form-group>
          </b-col>
          <b-col sm="4">           
            <b-form-group label="Comentarios" label-for="internal_note">
              <b-form-textarea name="internal_note" v-model="internal_note" />    
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mt-2 mr-1" type="button" @click="saveForm">
              Crear presupuesto
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
  import { BFormTextarea, BButton, BForm, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol,  BCard,BCardText} from 'bootstrap-vue'  
  import vSelect from 'vue-select';
  export default {
    components : { BFormTextarea,vSelect, BButton, BForm, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText,},
    data() {
      return {
        task_id : this.$route.params.task,
        budget_date : new Date().toISOString().slice(0,10),
        internal_note: null,
        serials: [],
        payment_methods: [],
        clauses: [],
        serial_id: null,
        method_id: null,
        clause_id: null,
      }
    },
    created () {
      this.$http.get("/api/getSerials").then((response) => {
        this.serials = response.data;       
      })
      this.$http.get("/api/getMethods").then((response) => {
        this.payment_methods = response.data;       
      })
      this.$http.get("/api/getClauses").then((response) => {
        this.clauses = response.data;       
      })
    },
    methods: {
      getOptionKey(option) {
        return option ? option.id : null;
      },
      saveForm : async function (e) {
        this.show = true;
        var formData = new FormData(this.$refs.formReport);
        formData.set('serial_id', this.serial_id ? this.serial_id.id : null);
        formData.set('clause_id', this.clause_id ? this.clause_id.id : null);
        formData.set('method_id', this.method_id ? this.method_id.id : null);
        await this.$http({
          method: "post",
          url: "/api/insertBudget",
          data: formData,
        }).then(response => {
          let id = response.data
          this.show = false;
          this.$router.push("/budget/"+id);
        })
          .catch(response => { window.console.log(response) });
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>